import {
  Box,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from "@chakra-ui/react"
import React, { useEffect } from "react"
import { FormattedMessage, useIntl } from "react-intl"
import { NumberControl } from "../../controls/NumberControl"
import { useFormContext, useWatch } from "react-hook-form"
import { TcoFormData } from "../../TcoForm"
import { getKeyToKeyMap } from "../../../../utils/getKeyToKeyMap"
import { InfoIcon } from "@chakra-ui/icons"
import { PopUp } from "../../../shared/PopUp/PopUp"
import { PressureTemperature } from "../../../../models/pressureTemperature"

export type DesignDataControlsData = {
  tFlowWinter: string
  tFlowSummer: string
  tReturnWinter: string
  tReturnSummer: string
  tSoilWinter: string
  tSoilSummer: string
  daysInOperationWinter: string
  daysInOperationSummer: string
  pressure: string
  soilCover: string
  lambdaSoil: string
}

export const getDefaultDesignData = (): DesignDataControlsData => ({
  tFlowWinter: "80",
  tFlowSummer: "70",
  tReturnWinter: "35",
  tReturnSummer: "40",
  tSoilWinter: "4",
  tSoilSummer: "14",
  daysInOperationWinter: "215",
  daysInOperationSummer: "150",
  pressure: "6",
  soilCover: "0.60",
  lambdaSoil: "1.60",
})
const designDataKeys = getKeyToKeyMap(getDefaultDesignData())

type DesignDataProps = {
  namePrefix: string
  onPressureTemperatureChange?: (
    pressureTemperature: PressureTemperature
  ) => void
  printMode?: boolean
}
export const DesignData: React.FC<DesignDataProps> = ({
  namePrefix,
  onPressureTemperatureChange,
  printMode = false,
}) => {
  const intl = useIntl()
  const {
    control,
    setValue,
    getValues,
    formState: { isValid },
    trigger,
  } = useFormContext<TcoFormData>()
  const textColor = printMode ? "black" : "white"

  // daysInOperationSummer is dependant on daysInOperationWinter
  const daysInOperationWinter = useWatch({
    name: "designData.daysInOperationWinter",
    control,
  })

  useEffect(() => {
    const summerValue = 365 - Number(daysInOperationWinter)
    setValue("designData.daysInOperationSummer", summerValue.toString())
  }, [daysInOperationWinter, setValue])

  const pressureBar = useWatch({
    control,
    name: "designData.pressure",
  })
  const tFlowWinter = useWatch({
    control,
    name: "designData.tFlowWinter",
  })
  const tFlowSummer = useWatch({
    control,
    name: "designData.tFlowSummer",
  })
  const tReturnWinter = useWatch({
    control,
    name: "designData.tReturnWinter",
  })
  const tReturnSummer = useWatch({
    control,
    name: "designData.tReturnSummer",
  })

  useEffect(() => {
    if (
      pressureBar &&
      tFlowWinter &&
      tFlowSummer &&
      tReturnWinter &&
      tReturnSummer
    ) {
      if (!isValid) {
        trigger()
        return
      }
      const handler = setTimeout(() => {
        const temperatureArray = [
          Number(tFlowWinter),
          Number(tFlowSummer),
          Number(tReturnWinter),
          Number(tReturnSummer),
        ]
        const minTemperature = Math.min(...temperatureArray)
        const maxTemperature = Math.max(...temperatureArray)
        const pressure = Number(pressureBar)

        onPressureTemperatureChange?.({
          pressureBar: pressure,
          minMediaTemperature: minTemperature,
          maxMediaTemperature: maxTemperature,
        })
      }, 1000)

      // Cancel the timeout if value changes (also on unmount)
      return () => {
        clearTimeout(handler)
      }
    }
  }, [
    pressureBar,
    tFlowWinter,
    tFlowSummer,
    tReturnWinter,
    tReturnSummer,
    onPressureTemperatureChange,
    isValid,
    trigger,
  ])

  return (
    <Box>
      <Heading color={textColor} as="h5" size="md">
        <FormattedMessage
          id="app.designData.title"
          defaultMessage="Design data"
          description="Design data title"
        />
      </Heading>

      <Grid
        marginTop={12}
        templateColumns="200px 60px 20px 60px 20px"
        templateRows="repeat(8, minmax(30px, auto))"
        gap={2}
      >
        {/* Titles row*/}
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1}>
          <Text fontSize="sm" color={textColor} textAlign={"center"}>
            <FormattedMessage
              id="app.designData.winter"
              defaultMessage="Winter"
              description="winter"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1}>
          <Text fontSize="sm" color={textColor} textAlign={"center"}>
            <FormattedMessage
              id="app.designData.summer"
              defaultMessage="Summer"
              description="summer"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />

        {/* T flow row*/}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.tFlow"
              defaultMessage="T flow [{degree}C]"
              description="T flow celcius"
              values={{
                degree: "\u00b0",
              }}
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.tFlowWinter}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0",
                  }
                ),
              },
              max: {
                value: 120,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "120" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.tFlowSummer}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0",
                  }
                ),
              },
              max: {
                value: 120,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "120" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.tFlowInfoIcon"
              defaultMessage="Min / max value is 0{degree}C to 120{degree}C - Temperature higher than max allowable continuous temperature for the different FlexPipe system will eliminate the use of these systems"
              description="T flow info icon description"
              values={{
                degree: "\u00b0",
              }}
            />
          </PopUp>
        </GridItem>

        {/* T return row*/}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.tReturn"
              defaultMessage="T return [{degree}C]"
              description="T return celcius"
              values={{
                degree: "\u00b0",
              }}
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.tReturnWinter}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 1,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "1",
                  }
                ),
              },
              max: {
                value: 120,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "120" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.tReturnSummer}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 1,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "1",
                  }
                ),
              },
              max: {
                value: 120,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "120" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.tReturnInfoIcon"
              defaultMessage="Min / max value is 0{degree}C to 120{degree}C - Temperature higher than max allowable continuous temperature for the different FlexPipe system will eliminate the use of these systems"
              description="T return info icon description"
              values={{
                degree: "\u00b0",
              }}
            />
          </PopUp>
        </GridItem>

        {/*T soil row*/}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.tSoil"
              defaultMessage="T soil [{degree}C]"
              description="T soil celcius"
              values={{
                degree: "\u00b0",
              }}
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.tSoilWinter}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: -10,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "-10",
                  }
                ),
              },
              max: {
                value: 50,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "50" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.tSoilSummer}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: -10,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "-10",
                  }
                ),
              },
              max: {
                value: 50,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "50" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.tSoilInfoIcon"
              defaultMessage="Min / max value is -10{degree}C to 50{degree}C, - Note summer and winter temperature should correspond to relevant period (days) given in the fields"
              description="T soil info icon description"
              values={{
                degree: "\u00b0",
              }}
            />
          </PopUp>
        </GridItem>

        {/*Days in operation*/}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.daysInOperation"
              defaultMessage="Days in operation"
              description="Days in operation"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.daysInOperationWinter}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0",
                  }
                ),
              },
              max: {
                value: 365,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "365" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.daysInOperationSummer}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0",
                  }
                ),
              },
              max: {
                value: 365,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "365" }
                ),
              },
              validate: () => {
                const total =
                  Number(getValues("designData.daysInOperationWinter")) +
                  Number(getValues("designData.daysInOperationSummer"))
                if (total > 365) {
                  return "Winter and summer max 365"
                } else {
                  return undefined
                }
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.daysInOperationInfoIcon"
              defaultMessage="Min / max value is 0 day to 365 days, sum of days for summer and winter in operation is max 365 days - can also be less if the system is not in use all year"
              description="Days in operation info icon description"
            />
          </PopUp>
        </GridItem>

        {/*Pressure [bar]*/}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.pressure"
              defaultMessage="Pressure [bar]"
              description="T soil celcius"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.pressure}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0",
                  }
                ),
              },
              max: {
                value: 25,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "25" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={false}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.pressureInfoIcon"
              defaultMessage="Min / max value is 0 to 25 bar - Pressure higher than max operating pressure for  the different FlexPipe system will eliminate the use of these systems"
              description="Pressure info icon description"
            />
          </PopUp>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1} />

        {/*Soil Cover*/}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.soilCover"
              defaultMessage="Soil Cover [m]"
              description="Soil Cover in metres"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.soilCover}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0.4,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0,4",
                  }
                ),
              },
              max: {
                value: 3,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "3" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={true}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.soilCoverInfoIcon"
              defaultMessage="Min / max value is 0,4 m to 3 m"
              description="Soil cover info icon description"
            />
          </PopUp>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1} />

        {/*Lambda Soil [W/mK] */}
        <GridItem rowSpan={1} colSpan={1}>
          <Text color={textColor}>
            <FormattedMessage
              id="app.designData.lambdaSoil"
              defaultMessage="Lambda Soil [W/mK]"
              description="Lambda Soil [W/mK]"
            />
          </Text>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <NumberControl
            control={control}
            name={namePrefix + designDataKeys.lambdaSoil}
            rules={{
              required: {
                value: true,
                message: intl.formatMessage({
                  id: "design-data.required",
                  defaultMessage: "Required",
                }),
              },
              min: {
                value: 0.4,
                message: intl.formatMessage(
                  {
                    id: "design-data.min",
                    defaultMessage: "Min val is {value}",
                  },
                  {
                    value: "0,4",
                  }
                ),
              },
              max: {
                value: 2.5,
                message: intl.formatMessage(
                  {
                    id: "design-data.max",
                    defaultMessage: "Max val is {value}",
                  },
                  { value: "2,5" }
                ),
              },
            }}
            isReadOnly={printMode}
            allowDecimals={true}
          />
        </GridItem>
        <GridItem rowSpan={1} colSpan={1}>
          <PopUp triggerElement={<InfoIcon color="white" />}>
            <FormattedMessage
              id="app.designData.lambdaSoilInfoIcon"
              defaultMessage="Min / max value is 0,4 W/mK to 2,5 W/mK"
              description="Lambda soil info icon description"
            />
            <UnorderedList>
              <ListItem>
                <FormattedMessage
                  id="app.designData.lambdaSoilInfoIconDry"
                  defaultMessage="Soil, λ = 1,0 W/mK = dry soil"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="app.designData.lambdaSoilInfoIconNormal"
                  defaultMessage="Soil, λ = 1,6 W/mK = Normal soil"
                />
              </ListItem>
              <ListItem>
                <FormattedMessage
                  id="app.designData.lambdaSoilInfoIconWet"
                  defaultMessage="Soil, λ = 2,0 W/mK = Wet soil"
                />
              </ListItem>
            </UnorderedList>
          </PopUp>
        </GridItem>
        <GridItem rowSpan={1} colSpan={1} />
        <GridItem rowSpan={1} colSpan={1} />
      </Grid>
    </Box>
  )
}
