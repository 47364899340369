import { Button, ButtonGroup } from "@chakra-ui/button"
import { Flex } from "@chakra-ui/layout"
import { FaPrint } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { useNavigate } from "react-router"
import { BrandedButton } from "../../components/shared/BrandedButton"

const PrintOptions = () => {
  const navigate = useNavigate()

  const print = () => {
    window.print()
  }

  return (
    <Flex
      className="print-hidden"
      w="100%"
      ms="-5"
      mb="5"
      direction="row"
      flexDirection="row-reverse"
      pos="sticky"
      top="12px"
      zIndex="100"
    >
      <ButtonGroup>
        <Button size="md" onClick={() => navigate("/")}>
          <FormattedMessage id="cancel" defaultMessage="Cancel" />
        </Button>
        <BrandedButton
          primary
          autoFocus
          rightIcon={<FaPrint />}
          size="md"
          onClick={print}
        >
          <FormattedMessage
            id="report.print-button"
            defaultMessage="Print"
            description="Print report"
          />
        </BrandedButton>
      </ButtonGroup>
    </Flex>
  )
}

export default PrintOptions
