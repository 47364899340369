import { Button, ButtonProps } from "@chakra-ui/button"

interface Props {
  primary?: boolean
}

export const BrandedButton = (props: ButtonProps & Props) => {
  const { primary } = props

  return (
    <Button
      color={primary ? "white" : "brand.blue"}
      backgroundColor={primary ? "brand.red" : "transparent"}
      borderWidth={primary ? "0" : "1px"}
      borderColor="brand.blue"
      {...(primary && { _hover: { bg: "red.600" } })}
      {...(primary && { _active: { bg: "red.600" } })}
      {...props}
    />
  )
}
