import { HStack } from "@chakra-ui/layout"
import {
  Center,
  Heading,
  Text,
  Spacer,
  Link,
  Tag,
  TagLabel,
  TagLeftIcon,
} from "@chakra-ui/react"
import styled from "@emotion/styled"
import { FaRegStar } from "react-icons/fa"
import { FormattedMessage } from "react-intl"
import { ReactComponent as Logo } from "../../../assets/logo.svg"
import { InfoPopover } from "./InfoPopover"
import { MemoizedHeaderMenu } from "./Menu/HeaderMenu"
import { Link as RouterLink, useNavigate } from "react-router-dom"
import { useSavedQuery } from "../../../hooks/useSavedQuery"
import { reloadPage } from "../../../utils/utilFuctions"
import { ContactFormModal } from "./ContactFormModal"

const LogoLink = styled(Link)`
  display: inline-block;
  text-decoration: none !important;
`

const FavoriteTag = () => {
  const savedQuery = useSavedQuery()

  if (savedQuery == null || !savedQuery.name) {
    return null
  }

  return (
    <>
      <Text color="white" opacity="30%">
        |
      </Text>
      <Tag
        size="xl"
        p="4px"
        ps="8px"
        pe="8px"
        variant="solid"
        bg="brand.red"
        borderRadius="full"
        fontSize="16px"
      >
        <TagLeftIcon as={FaRegStar} />
        <TagLabel>{savedQuery.name}</TagLabel>
      </Tag>
    </>
  )
}

export const LogoHeader = () => {
  const navigate = useNavigate()
  return (
    <HStack
      as="header"
      h="56px"
      minH="56px"
      bg="brand.blue"
      spacing="5"
      pe="20px"
    >
      <Center ms="32px">
        <HStack>
          <LogoLink
            title="LOGSTOR TCO"
            onClick={() => {
              reloadPage(navigate)
            }}
          >
            <HStack spacing="16px">
              <Logo />
              <Text color="white" opacity="30%">
                |
              </Text>
              <Heading
                as="h1"
                fontStyle="normal"
                fontWeight="300"
                fontSize="20px"
                color="white"
              >
                TCO – Total Cost of Ownership
              </Heading>
            </HStack>
          </LogoLink>
          <FavoriteTag />
        </HStack>
      </Center>
      <Spacer />
      <Center>
        <HStack>
          <ContactFormModal />
          <FavoritesLink />
          <InfoPopover />
          <MemoizedHeaderMenu />
        </HStack>
      </Center>
    </HStack>
  )
}

const FavoritesLink = () => {
  return (
    <Link
      as={RouterLink}
      to="/favourites"
      fontSize="14px"
      textColor="white"
      me="2"
    >
      <Center>
        <FaRegStar />
        <Text ms="2">
          <FormattedMessage defaultMessage="Favourites" id="menu.favorites" />
        </Text>
      </Center>
    </Link>
  )
}
